<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      levelthree: [],
      level_three_chart_of_accounts_copy_1: [],
      LevelThreeChartOfAccountsCopy1: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchLevelThreeChartOfAccountsCopy1: null,
      LevelThreeChartOfAccountsCopy1SearchMode: false,
      // appprices:[]
    };
  },
  methods: {
    addLevelThreeChartOfAccountsCopy1() {
      var data = {
        title: "popups.addlevel",
        inputs: [
          {
            model: "level_two_chart_of_account_id",
            type: "select",
            options: (() => {
              return this.levelthree.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.leveltwochart",
          },

          { model: "name", type: "text", label: "popups.name" },

          { model: "code", type: "text", label: "popups.code" },
          {
            model: "is_editable",
            type: "select",
            options: [
              { label: "Editable", value: 1 },
              { label: "Uneditable", value: 0 },
            ],
            label: "popups.edit",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .post("level-three-chart-of-accounts-copy-1", obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editLevelThreeChartOfAccountsCopy1(app) {
      var data = {
        title: "popups.editlevel",
        inputs: [
          {
            model: "level_two_chart_of_account_id",
            type: "select",
            options: (() => {
              return this.levelthree.map((type) => {
                return { value: type.id, label: type.name };
              });
            })(),
            label: "popups.leveltwochart",
            value: app.level_two_chart_of_account,
          },

          {
            model: "name",
            type: "text",
            label: "popups.name",
            value: app.name,
          },

          {
            model: "code",
            type: "text",
            label: "popups.code",
            value: app.code,
          },
          {
            model: "is_editable",
            type: "select",
            options: [
              { label: "Editable", value: 1 },
              { label: "Uneditable", value: 0 },
            ],
            label: "popups.edit",
            value: app.is_editable,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http
                .put("level-three-chart-of-accounts-copy-1", app.id, obj)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.LevelThreeChartOfAccountsCopy1SearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("level-three-chart-of-accounts-copy-1/search", {
          search: this.searchModel,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.level_three_chart_of_accounts_copy_1 = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.LevelThreeChartOfAccountsCopy1SearchMode = false;
      this.get(this.page);
    },
    getLevelThreeChartOfAccountsCopy1() {
      this.http.get("level-three-chart-of-accounts-copy-1").then((res) => {
        this.level_three_chart_of_accounts_copy_1 = res.data;
      });
    },
    deleteLevelThreeChartOfAccountsCopy1(app) {
      var data = {
        title: "popups. deletesms",
        template: `
          <h5>Are you sure you want to delete ?</h5> 
   
        `,
        buttons: [
          {
            text: "Delete",
            closer: true,
            color: "danger",
            handler: () => {
              console.log(app);
              this.http
                .delete("level-three-chart-of-accounts-copy-1", app.id)
                .then(() => {
                  this.get(this.page);
                });
            },
          },
          {
            text: "Cancel",
            closer: true,
            color: "light",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    getleveltwo() {
      this.http.get("level-two-chart-of-accounts").then((res) => {
        this.levelthree = res.data;
      });
    },
    get(page) {
      console.log(page);
      this.http
        .post("level-three-chart-of-accounts-copy-1/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.level_three_chart_of_accounts_copy_1 = res.data;
        });
    },
  },
  created() {
    this.get(1);
    // this.getLevelThreeChartOfAccountsCopy1();
    this.getleveltwo();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="
        $t(
          'menu.menuitems.tree-of-accounts.subItems.level_three_chart_of_accounts'
        )
      "
    />

    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close close text-light"
          v-if="LevelThreeChartOfAccountsCopy1SearchMode"
          @click="cancelappsearchMode()"
        ></button>

        <button
          type="button"
          @click="addLevelThreeChartOfAccountsCopy1()"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
        <thead>
          <tr
            class="text-light text-center"
            style="background-color: #2a3042 !important"
          >
            <th scope="col">{{ $t("level_three.id") }}</th>
            <th scope="col">
              {{ $t("level_three.level_two_chart_of_account") }}
            </th>
            <th scope="col">{{ $t("level_three.name") }}</th>
            <th scope="col">{{ $t("level_three.code") }}</th>
            <th scope="col">{{ $t("level_three.created") }}</th>
            <th scope="col">{{ $t("level_three.updated") }}</th>
            <th scope="col">{{ $t("level_three.operations") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(app, index) in level_three_chart_of_accounts_copy_1"
            :key="app"
            class="text-center"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ app.level_two_chart_of_account?.name }}</td>
            <td>{{ app.name }}</td>
            <td>{{ app.code }}</td>
            <td>{{ app?.created.split("T")[0] }}</td>
            <td>{{ app?.updated.split("T")[0] }}</td>
            <td>
              <a
                class="btn btn-primary me-2"
                v-if="app.is_editable == 1"
                @click="editLevelThreeChartOfAccountsCopy1(app)"
                href="javascript: void(0);"
                role="button"
                >{{ $t("popups.edit") }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!--   Apps  Pagination     -->
    <div>
      <ul class="pagination pagination-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: p == page }"
          v-for="p in tot_pages"
          :key="p"
        >
          <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
            p
          }}</a>
        </li>

        <li class="page-item" :class="{ disabled: page == tot_pages }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page + 1)"
            aria-label="Next"
          >
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
